@tailwind base;
@tailwind components;
@tailwind utilities;

@import url(./fonts.css);

/**
 * @notes
 *  This was added because DayPicker's library was allowing the user to
 *  select days even if they were disabled, so this way if the day div
 *  has this class we'll force and disable all pointer-events.
 */
.DayPicker-Day--disabled {
  pointer-events: none !important;
}
