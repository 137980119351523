@tailwind base;
@tailwind components;

@layer base {
  /* FIXME: Use body instead of '*' */
  * {
    @apply font-body;
  }
}

@tailwind utilities;
