:root {
  --black: #000000;
  --dark-black: #122c34;
  --red: #ed0e61;
  --white: #ffffff;
  --mystic: #e3e8ef;
  --grey-button-calendar: var(--mystic);
  --grey: #748094;
  --separator-text: #a3a3a3;
  --button-grey-bg: #f1f2f5;
  --grey100: #f6f8fa;
  --pagination-border: #e2e3e6;
  --positive-green: #e3f6ed;
  --green: #34a853;
  --vivid-green: #27ae60;
  --green-dark: #51a351;
  --green-light: #e0f6e0;
  --negative-red: #fae9e8;
  --light-grey: rgba(227, 232, 239, 0.2);
  --light-grey-plus: #858585;
  --lighter-grey: #b4b3c2;
  --tertiary: #9ea6b2;
  --yellow-dark: #876800;
  --yellow: #fff1b8;
  --blue-dark: #0050b3;
  --lighter-blue: #eaf5ff;
  --light-blue: #32a1ff;
  --blue: #91d5ff;
  --dark-blue: #211c5c;
  --dark-blue-5: rgba(33, 28, 92, 0.05);
  --dark-blue-plus: #161d2e;
  --hsa-bg: var(--dark-blue);
  --benepass-bg: var(--white);
  --card-border: #bbbbbb;
  --dark: var(--dark-blue);
  --dark-red: #df3333;
  --light-red: #fae4e4;
  --dark-grey: #4a5568;
  --grey-light: #efefef;
  --main: var(--dark-blue);
  --grayish-blue: #5e5b84;
  --dark-black: #161d2e;
  --sidebarItemColor: var(--light-blue);
  --title: var(--dark-blue);
  --subtitle: var(--grey);
  --tabColorActive: var(--dark-blue);
  --tabColorInactive: var(--grey);
  --tag-card-error: var(--dark-red);
  --tag-card-inactive: var(--grey);
  --tag-card-active: var(--green);
  --button-bg: var(--light-blue);
  --button-color: var(--white);
  --button-primary-bg: var(--light-blue);
  --button-primary-color: var(--white);
  --button-warning-bg: var(--dark-red);
  --error: var(--dark-red);
  --individual: #f2c94c;
  --orange-light: #faeee4;
  --orange: #ee9e27;
  --family: #f2994a;
  --tag-discount-bg: #ececec;
  --purple: #453f93;
  --purple-light: #f0effe;
  --education-bg: #e6e6e9;
  --theme-color: var(--dark-blue);
  --pink: #ed0e61;
  --pink-dark: #db004f;
  --error-text: #dc3545;
  --error-bg: #fcf1f2;
}
