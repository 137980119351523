.info {
  border-radius: 3px;
  width: 24px;
  height: 24px;
  margin-left: 12px;
  display: inline-flex;
  align-self: center;
  align-items: center;
  justify-content: center;
  color: var(--grey);
}

.gray {
  border-radius: 3px;
  margin-left: 12px;
  display: inline-flex;
  align-self: center;
  align-items: center;
  justify-content: center;
  color: var(--grey);
}
